<template>
  <div class="layer-type bg-gray-800 flex flex-col">
    <div class="layer layer-md border-gray-500 text-gray-200">
      <!-- <div class="left-side flex justify-between items-center truncate mr-2">
        <button type="button" name="button" class="btn btn-transparent focus:outline-none">
          <fa-icon icon="layer-plus" size="sm" class="text-gray-600" />
        </button>
        <div class="text-gray-800 text-sm font-bold truncate ml-1"><a href="/editor-timeline" target="_blank">Open in
            new window</a> </div>
      </div>
      <div class="right-side flex justify-between items-center text-xs">
        <button type="button" name="button" class="btn btn-transparent focus:outline-none">
          <fa-icon icon="minus-square" size="sm" class="text-gray-600" />
        </button>
       <button type="button" name="button" class="btn btn-transparent focus:outline-none">
          <fa-icon icon="plus-square" size="sm" class="text-gray-600" />
        </button>
      </div> -->
      <div class="left-side flex justify-between items-center truncate mr-2">
        <a
          href="#"
          class="p-2 text-gray-300 hover:text-gray-500 focus:outline-none"
          @click.prevent="jumpToPrev"
        >
          <fa-icon
            icon="backward-step"
            class="fa-fw"
            size="sm"
          />
        </a>

        <a
          href="#"
          class="p-2 text-gray-300 hover:text-gray-500 focus:outline-none font-mono text-xs"
          @click.prevent="jumpBack10"
        >
          -10
        </a>

        <a
          href="#"
          class="p-2 text-gray-300 hover:text-gray-500 focus:outline-none font-mono text-xs"
          @click.prevent="jumpForward10"
        >
          +10
        </a>

        <a
          href="#"
          class="p-2 text-gray-300 hover:text-gray-500 focus:outline-none"
          @click.prevent="jumpToNext"
        >
          <fa-icon
            icon="forward-step"
            class="fa-fw"
            size="sm"
          />
        </a>
      </div>

      <div class="right-side flex justify-between items-center truncate ml-2">
        <a
          href="#"
          class="p-2 text-gray-300 hover:text-gray-500 focus:outline-none"
          :class="{'disabled' : !canDecreasePlaybackRate }"
          @click.prevent="decreasePlaybackRate"
        >
          <fa-icon
            icon="backward"
            class="fa-fw"
            size="sm"
          />
        </a>
        <span class="text-xs text-gray-300 font-mono font-bold w-8 text-center">{{ playbackRate }}x</span>
        <a
          href="#"
          class="p-2 text-gray-300 hover:text-gray-500 focus:outline-none"
          :class="{'disabled' : !canIncreasePlaybackRate }"
          @click.prevent="increasePlaybackRate"
        >
          <fa-icon
            icon="forward"
            class="fa-fw"
            size="sm"
          />
        </a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'SidebarOptionsRow',

  props: {
    playbackRate: {
      type: Number,
      default: 1
    }
  },

  computed: {

    canIncreasePlaybackRate () {
      return (this.playbackRate < 10)
    },

    canDecreasePlaybackRate () {
      return (this.playbackRate > 0.2)
    }
  },

  methods: {

    increasePlaybackRate () {
      this.$emit('increase-playbackrate')
    },

    decreasePlaybackRate () {
      this.$emit('decrease-playbackrate')
    },

    jumpToPrev () {
      this.$emit('jump-to-prev')
    },

    jumpBack10 () {
      this.$emit('jump-to-time-relative', -10)
    },

    jumpForward10 () {
      this.$emit('jump-to-time-relative', 10)
    },

    jumpToNext () {
      this.$emit('jump-to-next')
    }

  }
}
</script>
