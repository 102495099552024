<template>
  <div class="layer-type bg-gray-800">
    <div class="layer border-gray-700 text-gray-200">
      <div class="left-side flex justify-between items-center">
        <div class="w-6 flex items-center" />

        <div
          class="layer-name text-gray-300 truncate select-none text-xs hover:underline cursor-pointer"
          @click.prevent="addLayer"
        >
          <fa-icon
            icon="plus"
            size="sm"
            class="mr-1"
          /> New Layer
        </div>
      </div>
    </div>
    <div class="right-side flex justify-between items-center text-xs" />
  </div>
</template>
<script>
export default {
  name: 'TimelineSidebarEmpty',

  methods: {
    addLayer () {
      this.$emit('new-layer')
    }
  }
}
</script>
