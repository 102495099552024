<template>
  <div
    class="bg-gray-800 layer-type hover:bg-gradient-to-br from-indigo-600 to-purple-700 group"
    @contextmenu.prevent="showMenu"
  >
    <VDropdown
      placement="right"
      class="flex w-full truncate"
      auto-hide
      :shown="isMenuShown"
      :triggers="[]"
      @hide="isMenuShown = false"
    >
      <div class="text-gray-200 border-gray-700 layer">
        <div
          class="flex items-center justify-between min-w-0 left-side group"
          style="cursor: grab"
        >
          <div class="flex items-center w-8">
            <div class="btn btn-no-style btn-xs">
              <fa-icon
                icon="photo-film"
                size="xs"
                class="mr-2 text-blue-500"
              />
            </div>
          </div>
          <div v-if="isNestingEnabled" class="flex items-center w-6 text-xs">
            <template v-if="layer.children">
              <button
                class="outline-none btn btn-no-style"
                type="button"
                name="button"
                @click.prevent="$emit('toggle-row', layer.id)"
              >
                <fa-icon
                  v-if="layer.expanded"
                  rotation="90"
                  icon="play"
                  size="xs"
                  class="mr-2 text-gray-600"
                />
                <fa-icon
                  v-else
                  icon="play"
                  size="xs"
                  class="mr-2 text-gray-600"
                />
              </button>
            </template>
          </div>

          <div
            class="text-xs text-gray-300 truncate select-none layer-name group-hover:text-white"
          >
            {{ layerName }}
          </div>
        </div>

        <div class="flex items-center justify-between text-xs right-side">
          <button
            v-if="canLockLayer"
            class="text-gray-400 outline-none btn btn-no-style"
            type="button"
            name="button"
            @click.prevent="$emit('lock-layer', layer.id)"
          >
            <fa-icon
              v-if="isLocked"
              icon="lock"
              class="hover:!text-indigo-200 group-hover:text-white"
              size="xs"
            />
            <fa-icon
              v-else
              icon="unlock"
              class="text-gray-400 hover:!text-indigo-200 group-hover:text-white"
              size="xs"
            />
          </button>

          <button
            v-if="canEnableLayer"
            class="text-gray-400 outline-none btn btn-no-style hover:!text-indigo-200 group-hover:text-white"
            type="button"
            name="button"
            @click.prevent="$emit('enable-layer', layer.id)"
          >
            <fa-icon
              v-if="!isEnabled"
              icon="eye-slash"
              class="text-gray-400 hover:!text-indigo-200 group-hover:text-white"
              size="xs"
            />
            <fa-icon v-else icon="eye" size="xs" />
          </button>

          <button
            v-if="canAddModule"
            class="text-gray-400 outline-none btn btn-no-style hover:!text-indigo-200 group-hover:text-white"
            type="button"
            name="button"
            @click.prevent="$emit('new-module', { layerId: layer.id })"
          >
            <fa-icon icon="plus" size="xs" />
          </button>
        </div>
      </div>

      <template v-if="layer.children && isNestingEnabled">
        <div v-show="layer.expanded" class="outline-none sub-layers">
          <timeline-sidebar-row
            v-for="child in layer.children"
            :key="child.id"
            :layer="child"
            @toggle-row="$emit('toggle-row', $event)"
            @enable-layer="$emit('enable-layer', $event)"
            @lock-layer="$emit('lock-layer', $event)"
          />
        </div>
      </template>
      <template #popper>
        <ul class="p-1 text-xs leading-loose text-white">
          <!-- <li v-if="canDuplicateLayer">
                <div @click.prevent="$emit('duplicate-layer', layer.id)" v-close-popover class="flex items-center justify-between text-white cursor-pointer hover:underline">
                  Duplicate layer <fa-icon icon="plus" />
                </div>
              </li> -->
          <li v-if="canRenameLayer">
            <div
              v-close-popover
              class="flex items-center px-2 py-1 text-xs border border-transparent rounded-md cursor-default group text-neutral-100 hover:bg-neutral-600 hover-neutral-600 hover:border-neutral-400"
              @click.prevent="$emit('rename-layer', layer.id)"
            >
              <fa-icon icon="pen-to-square" class="mr-1 fa-fw" />Rename layer
            </div>
          </li>
          <!-- <li v-if="canEnableLayer">
                <div @click.prevent="$emit('enable-layer', layer.id)" v-close-popover class="flex items-center justify-between text-white cursor-pointer hover:underline">
                  <template v-if="isEnabled">Enable layer <fa-icon icon="eye" /></template>
                  <template v-else>Disable layer <fa-icon icon="eye-slash" /></template>
                </div>
              </li>
              <li v-if="canLockLayer">
                <div @click.prevent="$emit('lock-layer', layer.id)" v-close-popover class="flex items-center justify-between text-white cursor-pointer hover:underline">
                  <template v-if="isLocked">Unlock layer <fa-icon icon="unlock" /></template>
                  <template v-else>Lock layer <fa-icon icon="lock" /></template>
                </div>
              </li> -->
          <li v-if="canTrashLayer">
            <div
              v-close-popover
              class="flex items-center px-2 py-1 text-xs border border-transparent rounded-md cursor-default group text-neutral-100 hover:bg-neutral-600 hover-neutral-600 hover:border-neutral-400"
              @click.prevent="$emit('trash-layer', layer.id)"
            >
              <fa-icon icon="trash-can" class="mr-1 text-red-500 fa-fw" />
              Delete layer
            </div>
          </li>
        </ul>
      </template>
    </VDropdown>
  </div>
</template>

<script>
// eslint-disable-next-line
const TimelineSidebarRow = () => import('./TimelineSidebarRow')

export default {
  name: 'TimelineSidebarRow',

  components: {
    // we need to use this name for self-recursion
    TimelineSidebarRow: 'timeline-sidebar-row'
  },

  data() {
    return {
      isMenuShown: false
    }
  },

  props: {
    layer: {
      required: true
    },
    type: {
      type: String,
      default: 'module'
    },
    activePopperLayer: {
      type: String,
      default: ''
    }
  },

  computed: {
    isValid() {
      return (
        this.layer.hasOwnProperty('resource_id') &&
        this.layer.resource_id !== null
      )
    },

    layerName() {
      if (this.layer.name !== undefined && this.layer.name !== '')
        return this.layer.name
      return 'Empty Layer'
    },

    isNestingEnabled() {
      return false
    },

    isLocked() {
      return this.layer.locked === true
    },

    isEnabled() {
      return this.layer.enabled === true
    },

    canAddModule() {
      return true
    },

    canLockLayer() {
      // if (this.layer.type === 'attribute' || this.layer.type === 'comments') return false
      return true
    },
    canTrashLayer() {
      return true
    },
    canEnableLayer() {
      // if (this.layer.type === 'attribute' || this.layer.type === 'comments') return false
      return true
    },
    canDuplicateLayer() {
      return true
      // return this.layer.type === 'layer'
    },
    canRenameLayer() {
      return true
      // return this.layer.type === 'layer'
    },

    hasLayerOptions() {
      return (
        this.canLockLayer ||
        this.canTrashLayer ||
        this.canEnableLayer ||
        this.canDuplicateLayer ||
        this.canRenameLayer
      )
    }
  },

  watch: {
    activePopperLayer() {
      if (this.activePopperLayer !== this.layer.id) {
        this.isMenuShown = false
      }
    }
  },

  methods: {
    showMenu() {
      this.isMenuShown = true
      this.$emit('active-popper-layer-changed', this.layer.id)
    }
  }
}
</script>
