<template>
  <div class="border-r border-gray-700 timeline-layer-types">
    <div class="w-full outline-none">
      <!-- <TimelineSidebarOptionsRow
        v-bind:playback-rate="playbackRate"
        v-on:increase-playbackrate="$emit('increase-playbackrate', $event)"
        v-on:decrease-playbackrate="$emit('decrease-playbackrate', $event)"
        v-on:jump-to-time-relative="$emit('jump-to-time-relative', $event)"
        v-on:jump-to-prev="$emit('jump-to-prev', $event)"
        v-on:jump-to-next="$emit('jump-to-next', $event)"/> -->

      <draggable
        v-model="layersComputed"
        v-bind="dragOptions"
        @start="drag = true"
        @end="drag = false"
      >
        <transition-group type="transition" :name="!drag ? 'flip-list' : null">
          <TimelineSidebarRow
            v-for="layer in layersComputed"
            :key="layer.id"
            draggable
            :layer="layer"
            :active-popper-layer="activePopperLayer"
            @toggle-row="$emit('toggle-row', $event)"
            @duplicate-layer="$emit('duplicate-layer', $event)"
            @enable-layer="$emit('enable-layer', $event)"
            @new-module="$emit('new-module', $event)"
            @lock-layer="$emit('lock-layer', $event)"
            @rename-layer="$emit('rename-layer', $event)"
            @trash-layer="$emit('trash-layer', $event)"
            @active-popper-layer-changed="activePopperLayer = $event"
          />
        </transition-group>
      </draggable>

      <TimelineSidebarEmpty @new-layer="$emit('new-layer', $event)" />
    </div>
  </div>
</template>
<script>
import TimelineSidebarEmpty from './sidebar/TimelineSidebarEmpty'
import TimelineSidebarRow from './sidebar/TimelineSidebarRow'
import TimelineSidebarOptionsRow from './sidebar/TimelineSidebarOptionsRow'
import draggable from 'vuedraggable'

export default {
  components: {
    TimelineSidebarEmpty,
    TimelineSidebarRow,
    TimelineSidebarOptionsRow,
    draggable
  },
  props: ['layers', 'playbackRate'],

  data() {
    return {
      drag: false,
      activePopperLayer: ''
    }
  },

  computed: {
    layersComputed: {
      get() {
        return this.layers
      },
      set(value) {
        let order = []

        value.forEach((row) => {
          order.push(row.id)
        })

        this.$emit('reorder-layers', order)
      }
    },

    dragOptions() {
      return {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost'
      }
    }
  }
}
</script>

<style>
.flip-list-move {
  transition: transform 0.5s;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
</style>
